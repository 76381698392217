// src/pages/Home.js
import React from "react";
import HomePageBanner from "../components/HomePageBanner";
import WelcomeSection from "../components/WelcomeSection";
import MissionVisionValues from "../components/MissionVisionValues";
import PortfolioSection from "../components/PortfolioSection";
import Testimonials from "../components/Testimonials";
import HelmetWrapper from "../components/common/HelmetWrapper";
import Services from "../components/Services";
import {
  BsBrush,
  BsCodeSlash,
  BsPhone,
  BsWrench,
  BsPalette,
  BsPencilSquare,
} from "react-icons/bs";
import portfolioItems from "../data/portfolioItems"; // Move portfolioItems to a separate data file

const homeServices = [
  {
    title: "Website Design",
    icon: <BsBrush size={50} />,
    description: "Visually stunning and user-friendly websites for your brand.",
  },
  {
    title: "Website Development",
    icon: <BsCodeSlash size={50} />,
    description:
      "Building robust and scalable websites with tailored features.",
  },
  {
    title: "Mobile App Development",
    icon: <BsPhone size={50} />,
    description: "High-performance apps for engaging mobile experiences.",
  },
  {
    title: "UI/UX Design",
    icon: <BsPencilSquare size={50} />,
    description: "Creating intuitive designs that resonate with your audience.",
  },
  {
    title: "Logo Design",
    icon: <BsPalette size={50} />,
    description: "Eye-catching designs that effectively convey your message.",
  },
  {
    title: "Website Maintenance",
    icon: <BsWrench size={50} />,
    description: "Reliable updates and support to keep your website secure.",
  },
];

const Home = () => {
  return (
    <div>
      <HelmetWrapper title="Home" />
      <HomePageBanner />
      <WelcomeSection />
      <MissionVisionValues />

      <Services title="Our Services" services={homeServices} />
      <PortfolioSection portfolioItems={portfolioItems} title="Our Portfolio" />
      <Testimonials />
    </div>
  );
};

export default Home;
