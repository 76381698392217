import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import welcomePic1 from "../assets/images/kr-welcome-pic10.jpg";
import welcomePic2 from "../assets/images/kr-welcome-pic11.jpg";
import welcomeTrophyPic from "../assets/images/kr-welcome-pic4.png";
import "./WelcomeSection.css";

const WelcomeSection = () => {
  return (
    <section className="welcome-section">
      <Container>
        <Row>
          <Col md={6} className="image-col">
            <div className="welcome-left-section">
              <img src={welcomePic1} alt="Welcome Pic1" className="left-pic1" />
              <img src={welcomePic2} alt="Welcome Pic2" className="left-pic2" />
              <div className="experience-box2">
                <div className="inner-content">
                  <img src={welcomeTrophyPic} alt="icon" />
                  <h3>10</h3>
                  <p>Years of excellence</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="text-col">
            <h2>Welcome to Krishabh</h2>
            <p>
              We bring you the finest services and solutions tailored to meet
              your needs. Our team of experts is dedicated to ensuring your
              success through innovative and customized approaches.
            </p>

            <h3>Why Choose Us?</h3>
            <ul>
              <li className="list-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#04d4c7"
                  width="24px"
                  height="24px"
                  className="list-icon"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5l-4-4 1.41-1.41L10 13.67l6.59-6.59L18 8.5l-8 8z" />
                </svg>
                <div>
                  <strong>Customized Solutions</strong>: Every client is unique,
                  and so are our strategies.
                </div>
              </li>
              <li className="list-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#04d4c7"
                  width="24px"
                  height="24px"
                  className="list-icon"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5l-4-4 1.41-1.41L10 13.67l6.59-6.59L18 8.5l-8 8z" />
                </svg>
                <div>
                  <strong>Expert Team</strong>: Our professionals are skilled,
                  experienced, and passionate.
                </div>
              </li>
              <li className="list-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#04d4c7"
                  width="24px"
                  height="24px"
                  className="list-icon"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5l-4-4 1.41-1.41L10 13.67l6.59-6.59L18 8.5l-8 8z" />
                </svg>
                <div>
                  <strong>Client-Centric Approach</strong>: Your satisfaction is
                  our priority.
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WelcomeSection;
