import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import SharedButton from "./common/SharedButton";
import { useNavigate } from "react-router-dom";

const PortfolioSection = ({ portfolioItems = [], title }) => {
  const navigate = useNavigate(); // React Router hook for navigation

  return (
    <section className="portfolio-section">
      <Container>
        {title && <h2 className="section-title">{title}</h2>}
        <Row>
          {portfolioItems.map((item) => (
            <Col md={4} key={item.id}>
              <Card className="portfolio-card">
                <Card.Img variant="top" src={item.image} alt={item.title} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  {item.url ? (
                    <div className="d-flex justify-content-center gap-2">
                      <SharedButton
                        variant="secondary"
                        onClick={() =>
                          window.open(item.url, "_blank", "noopener,noreferrer")
                        }
                      >
                        Visit Website
                      </SharedButton>
                      <SharedButton
                        variant="secondary"
                        onClick={() => navigate(`/project-details/${item.id}`)}
                      >
                        View Project Details
                      </SharedButton>
                    </div>
                  ) : (
                    <span className="coming-soon-text">Coming Soon</span>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PortfolioSection;
