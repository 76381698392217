import React from "react";
import { Row, Col } from "react-bootstrap";

const TechStack = ({ stack }) => {
  return (
    <div className="tech-stack">
      <Row>
        {stack.map((tech, index) => (
          <Col
            key={tech.id}
            xs={12} // Two items per row on extra small screens
            sm={4} // Three items per row on small screens
            md={3} // Four items per row on medium screens
            lg={3} // Six items per row on large screens
            className="tech-item text-center"
          >
            <img
              src={tech.logo}
              alt={`${tech.name} logo`}
              className="tech-logo img-fluid"
            />
            <h2 className="logo-name mt-3">{tech.name}</h2>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TechStack;
