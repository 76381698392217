// src/pages/InternshipProgram.js

import React, { useState } from "react";
import IntroSection from "../components/common/IntroSection";
import HelmetWrapper from "../components/common/HelmetWrapper";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./internshipProgram.css";

const InternshipProgram = () => {
  // Example internship data
  const [internships] = useState([
    {
      id: 1,
      title: "Software Engineering Intern",
      description:
        "Join our software engineering team and work on real-world projects.",
      duration: "3 months",
      location: "Mumbai, India",
    },
    {
      id: 2,
      title: "Full Stack Intern",
      description:
        "Interns gain hands-on experience in coding, testing, and debugging.",
      duration: "3 Months",
      location: "Mumbai, India",
    },
    {
      id: 3,
      title: "Data Science Intern",
      description: "Work with data scientists to analyze and interpret data.",
      duration: "3 Months",
      location: "Mumbai, India",
    },
    {
      id: 4,
      title: "UI/UX Design Intern",
      description: "Assist in designing user interfaces and experiences.",
      duration: "3 months",
      location: "Mumbai, India",
    },
    {
      id: 5,
      title: "WordPress Developer Intern",
      description:
        "Troubleshoot and resolve website issues, ensuring optimal performance and user experience.",
      duration: "3 Months",
      location: "Mumbai, India",
    },
    {
      id: 6,
      title: "MERN Intern",
      description:
        "Develop and maintain web applications using MongoDB, Express.js, React, and Node.js.",
      duration: "3 months",
      location: "Mumbai, India",
    },
  ]);

  // Handle apply button click
  const handleApply = (internshipId) => {
    alert(`You have applied for internship ID: ${internshipId}`);
  };

  // Handle view details button click
  const handleViewDetails = (internshipId) => {
    alert(`View details for internship ID: ${internshipId}`);
  };

  return (
    <>
      <IntroSection
        title="Internship Program"
        description="Explore our available internship opportunities below."
      />
      <HelmetWrapper title="Internhsip Program" />
      <Container>
        <div className="internship-program">
          <Row className="g-4">
            {internships.map((internship) => (
              <Col key={internship.id} md={4}>
                <Card className="internship-card">
                  <Card.Body>
                    <Card.Title>{internship.title}</Card.Title>
                    <Card.Text>{internship.description}</Card.Text>
                    <Card.Text>
                      <strong>Duration:</strong> {internship.duration}
                    </Card.Text>
                    <Card.Text>
                      <strong>Location:</strong> {internship.location}
                    </Card.Text>
                    <Button
                      onClick={() => handleApply(internship.id)}
                      variant="primary"
                      className="me-2"
                    >
                      Apply Now
                    </Button>
                    <Button
                      onClick={() => handleViewDetails(internship.id)}
                      variant="secondary"
                    >
                      View Details
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default InternshipProgram;
