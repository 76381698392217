// src/components/Services.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./Service.css";

const Services = ({ title, description, services = [] }) => {
  return (
    <section className="service-section">
      <Container>
        {title && <h2 className="section-title">{title}</h2>}
        {description && <p className="section-description">{description}</p>}
        <Row>
          {services.map((service, index) => (
            <Col md={4} className="mb-4" key={service.id}>
              <div className="service-item">
                <div className="icon-modern">{service.icon}</div>
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Services;
