import wordpressLogo from "../assets/images/wordpress-logo.png";
import bootstrapLogo from "../assets/images/bootstrap-logo.png";
import woocommerceLogo from "../assets/images/woocommerce-logo.png";
import elementorLogo from "../assets/images/elementor-logo.png";
import reactLogo from "../assets/images/react-logo.png";
import tailwindLogo from "../assets/images/tailwind-logo.png";
import phpLogo from "../assets/images/php-logo.png";
import mysqlLogo from "../assets/images/mysql-logo.png";

const portfolioItems = [
  {
    id: 0, // Static ID
    title: "Kavya Books Store",
    image: require("../assets/images/kr-project-01-pic.jpg"),
    url: "https://kavyabooksstore.com",
    techStack: [
      { name: "WordPress", logo: wordpressLogo },
      { name: "Bootstrap", logo: bootstrapLogo },
      { name: "Woocommerce", logo: woocommerceLogo },
      { name: "Elementor", logo: elementorLogo },
    ],
  },
  {
    id: 1, // Static ID
    title: "Urmila Beauty Salon & Academy",
    image: require("../assets/images/kr-project-04-pic.png"),
    url: "https://urmilabeautysalon.com",
    techStack: [
      { name: "React JS", logo: reactLogo },
      { name: "tailwind CSS", logo: tailwindLogo },
      { name: "PHP", logo: phpLogo },
      { name: "Mysql", logo: mysqlLogo },
    ],
  },

  {
    id: 2, // Static ID
    title: "Journal of Orthopaedic Case Reports",
    image: require("../assets/images/kr-project-02-pic.jpg"),
    url: "https://jocr.co.in",
    techStack: [
      { name: "WordPress", logo: wordpressLogo },
      { name: "Bootstrap", logo: bootstrapLogo },
      { name: "PHP", logo: phpLogo },
      { name: "Mysql", logo: mysqlLogo },
    ],
  },

  {
    id: 3, // Static ID
    title: "NeuroPharmac Journal",
    image: require("../assets/images/kr-project-04-pic.jpg"),
    url: "https://www.neuropharmac.com",
    techStack: [
      { name: "WordPress", logo: wordpressLogo },
      { name: "Bootstrap", logo: bootstrapLogo },
      { name: "PHP", logo: phpLogo },
      { name: "Mysql", logo: mysqlLogo },
    ],
  },

  {
    id: 4, // Static ID
    title: "Prem Constuctions",
    image: require("../assets/images/kr-project-05-pic.png"),
    url: "",
    techStack: [
      { name: "React JS", logo: reactLogo },
      { name: "Bootstrap", logo: bootstrapLogo },
      { name: "PHP", logo: phpLogo },
      { name: "Mysql", logo: mysqlLogo },
    ],
  },

  {
    id: 5, // Static ID
    title: "Panchkarma Ayurveda",
    image: require("../assets/images/kr-project-06-pic.png"),
    url: "",
    techStack: [
      { name: "React JS", logo: reactLogo },
      { name: "Bootstrap", logo: bootstrapLogo },
      { name: "PHP", logo: phpLogo },
      { name: "Mysql", logo: mysqlLogo },
    ],
  },
  // Add other projects with unique IDs...
];

export default portfolioItems;
