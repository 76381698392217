// src/pages/Portfolio.js
import React from "react";
import { Container } from "react-bootstrap";
import HelmetWrapper from "../components/common/HelmetWrapper";
import CommonBanner from "../components/CommonBanner";
import PortfolioSection from "../components/PortfolioSection";
import portfolioItems from "../data/portfolioItems"; // Importing portfolioItems

const Portfolio = () => {
  return (
    <div className="portfolio-page">
      <HelmetWrapper title="Portfolio" />
      <CommonBanner title="Portfolio" />
      <Container>
        <PortfolioSection
          portfolioItems={portfolioItems}
          title="Our Portfolio"
        />
      </Container>
    </div>
  );
};

export default Portfolio;
