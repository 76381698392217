// src/App.js

import React, { useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import WebsiteDesign from "./pages/WebsiteDesign";
import WebsiteDevelopment from "./pages/WebsiteDevelopment";
import MobileAppDevelopment from "./pages/MobileAppDevelopment";
import WebsiteMaintenance from "./pages/WebsiteMaintenance";
import GraphicDesign from "./pages/GraphicDesign";
import SuccessPage from "./pages/SuccessPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.scss"; // Import the custom CSS
import ProjectDetails from "./pages/ProjectDetails";
import InternshipProgram from "./pages/InternshipProgram"; // Import the InternshipProgram component

const App = () => {
  const topRef = useRef(null);

  // Function to scroll to the top
  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <div ref={topRef}></div>
        <main className="flex-grow-1">
          <Routes>
            {/* Add routes */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Navigate to="/" replace />} />{" "}
            {/* Redirect /home to / */}
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/project-details/:id" element={<ProjectDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/website-design" element={<WebsiteDesign />} />
            <Route
              path="/website-development"
              element={<WebsiteDevelopment />}
            />
            <Route
              path="/mobile-app-development"
              element={<MobileAppDevelopment />}
            />
            <Route
              path="/website-maintenance"
              element={<WebsiteMaintenance />}
            />
            <Route path="/graphic-design" element={<GraphicDesign />} />
            <Route path="/internship" element={<InternshipProgram />} />{" "}
            {/* Add the new route */}
            {/* Fallback route for undefined paths */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer scrollToTop={scrollToTop} />{" "}
        {/* Pass scrollToTop function to Footer */}
      </div>
    </Router>
  );
};

export default App;
