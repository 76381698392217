import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import portfolioItems from "../data/portfolioItems"; // Move portfolioItems to a separate data file
import TechStack from "../components/TechStack";

import "./ProjectDetails.css"; // Custom CSS for this page

const ProjectDetails = () => {
  const { id } = useParams();
  const project = portfolioItems.find((item) => item.id === parseInt(id));

  if (!project) {
    return <div className="project-not-found">Project not found</div>;
  }

  return (
    <div className="project-details-page">
      <Container>
        <Row className="align-items-center mb-5">
          <Col md={6}>
            <h1 className="project-title">{project.title}</h1>
            <p className="project-description">
              {/* Add a short description for the project */}
              This project is a showcase of modern design, seamless
              functionality, and cutting-edge technology.
            </p>
            {project.url ? (
              <Button
                href={project.url}
                target="_blank"
                rel="noopener noreferrer"
                className="visit-button"
              >
                Visit Website
              </Button>
            ) : (
              <span className="coming-soon-text">Coming Soon</span>
            )}
          </Col>
          <Col md={6}>
            <img
              src={project.image}
              alt={project.title}
              className="project-image img-fluid"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Technology Stack</h2>
            <TechStack stack={project.techStack} />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2>Key Features</h2>
            <ul className="feature-list">
              <li>Responsive and user-friendly design</li>
              <li>Optimized for performance and scalability</li>
              <li>Built with the latest technologies</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectDetails;
