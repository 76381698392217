// src/pages/Service.js
import React from "react";
import HelmetWrapper from "../components/common/HelmetWrapper";
import CommonBanner from "../components/CommonBanner";
import IntroSection from "../components/common/IntroSection";
import Services from "../components/Services";
import {
  BsBrush,
  BsCodeSlash,
  BsPhone,
  BsPencilSquare,
  BsPalette,
  BsWrench,
} from "react-icons/bs";

const serviceList = [
  {
    title: "Website Design",
    icon: <BsBrush size={50} />,
    description: "Visually stunning and user-friendly websites for your brand.",
  },
  {
    title: "Website Development",
    icon: <BsCodeSlash size={50} />,
    description:
      "Building robust and scalable websites with tailored features.",
  },
  {
    title: "Mobile App Development",
    icon: <BsPhone size={50} />,
    description: "High-performance apps for engaging mobile experiences.",
  },
  {
    title: "UI/UX Design",
    icon: <BsPencilSquare size={50} />,
    description: "Creating intuitive designs that resonate with your audience.",
  },
  {
    title: "Logo Design",
    icon: <BsPalette size={50} />,
    description: "Eye-catching designs that effectively convey your message.",
  },
  {
    title: "Website Maintenance",
    icon: <BsWrench size={50} />,
    description: "Reliable updates and support to keep your website secure.",
  },
];

const Service = () => {
  return (
    <div className="services-page">
      <HelmetWrapper title="Our Services" />
      <CommonBanner title="Services" />
      <IntroSection
        title="Our Services"
        description="At Krishabh, we offer a range of services designed to help you achieve your business goals. Our team of experts is committed to providing innovative and tailored solutions to meet your unique needs."
      />
      <Services title="Our Full Range of Services" services={serviceList} />
    </div>
  );
};

export default Service;
