import React, { useState } from "react";
import { Navbar, Nav, Container, Offcanvas } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import "./Header.css"; // Import the new CSS file

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Krishabh Logo" />
        </Navbar.Brand>

        {/* Toggler for mobile view */}
        <Navbar.Toggle
          aria-controls="offcanvas-navbar-nav"
          onClick={handleShow}
          className="navbar-toggler" // Ensure you have only one toggler
        />

        {/* Offcanvas for the navbar links */}
        <Navbar.Offcanvas
          id="offcanvas-navbar-nav"
          aria-labelledby="offcanvas-navbar-nav-label"
          placement="end"
          show={show}
          onHide={handleClose}
          className={`offcanvas-custom ${show ? "show" : "hide"}`}
        >
          <Offcanvas.Header className="offcanvas-header-custom" closeButton>
            <Offcanvas.Title id="offcanvas-navbar-nav-label">
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" onClick={handleClose}>
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/about" onClick={handleClose}>
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/service" onClick={handleClose}>
                Services
              </Nav.Link>
              <Nav.Link as={Link} to="/portfolio" onClick={handleClose}>
                Portfolio
              </Nav.Link>
              <Nav.Link as={Link} to="/internship" onClick={handleClose}>
                Internship
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" onClick={handleClose}>
                Contact
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
